import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import stars5 from '../../images/home/testimonials/5_stars.png';
import appstore from '../../images/home/testimonials/app_store_border.png';
import playstore from '../../images/home/testimonials/play_store_border.png';
import arrowLeft from '../../images/home/testimonials/arrow_left.png';
import arrowRight from '../../images/home/testimonials/arrow_right.png';
import {
	StarsTest,
	TestimonialsHeader,
	TestimonialWrapper,
	TestimonialContainer,
	TestimonialHeadline,
	TestimonialBody,
	TestimonialFooter,
	TestimonialPage,
	StarsText,
	TestimonialStores,
	DownloadButtons,
	TestimonialSliderWrapper,
	SliderImages,
	TestimonialSliderContainer,
	SliderImage,
} from '../../styles';
import { useWindowSize } from '../../utils/general';

const Testimonials = ({ section, view, content, num, load }) => {
	const [person1, setPerson1] = useState('');
	const [person2, setPerson2] = useState('');
	const [person3, setPerson3] = useState('');
	const [person4, setPerson4] = useState('');
	const [person5, setPerson5] = useState('');
	const [person6, setPerson6] = useState('');
	const [show, setShow] = useState(1);
	const { isDesktop } = useWindowSize();

	if (load) {
		import('../../images/home/testimonials/testimonial1.png').then(img => setPerson1(img.default));
		import('../../images/home/testimonials/testimonial2.png').then(img => setPerson2(img.default));
		import('../../images/home/testimonials/testimonial3.png').then(img => setPerson3(img.default));
		import('../../images/home/testimonials/testimonial4.png').then(img => setPerson4(img.default));
		import('../../images/home/testimonials/testimonial5.png').then(img => setPerson5(img.default));
		import('../../images/home/testimonials/testimonial6.png').then(img => setPerson6(img.default));
	}

	const settings = {
		// dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		// afterChange: i => console.log('ran ' + i),
		beforeChange: (currentI, newI) => (isDesktop ? null : randomize()),
		// responsive: [
		// 	{
		// 		breakpoint: 992,
		// 		settings: {
		// 			slidesToShow: 2,
		// 		},
		// 	},
		// 	{
		// 		breakpoint: 570,
		// 		settings: {
		// 			slidesToShow: 1,
		// 		},
		// 	},
		// ],
	};

	const randomize = () => setShow(show === 1 ? 3 : show === 3 ? 5 : 1);

	const checkShow = int => (show === int || show + 1 === int ? true : false);

	const truncate = input => (input.length > 250 ? `${input.substring(0, 250)}...` : input);

	const getContent = text => content.find(({ node: { position } }) => position === `section${num}-${text}`).node;

	return (
		<StaticQuery
			query={graphql`
				query {
					allGoogleTestimonialsSheet {
						edges {
							node {
								name
								date
								title
								text
							}
						}
					}
				}
			`}
			render={({ allGoogleTestimonialsSheet }) => {
				const testimonials = allGoogleTestimonialsSheet.edges;

				return (
					<TestimonialPage left={arrowLeft} right={arrowRight}>
						<TestimonialsHeader center>{getContent('title')?.text}</TestimonialsHeader>
						<StarsText center>
							{getContent('subtitle')?.text}
							{/* {getContent('subtitle')?.text} | {getContent('downloads')?.text} */}
						</StarsText>
						<DownloadButtons>
							<a
								href="https://play.google.com/store/apps/details?id=ai.envel"
								target="_blank"
								rel="noopener noreferrer"
							>
								<TestimonialStores src={playstore} alt="play store" />
							</a>
							<a
								href="https://apps.apple.com/us/app/envel-mobile-banking/id1503505652"
								target="_blank"
								rel="noopener noreferrer"
							>
								<TestimonialStores src={appstore} alt="app store" className="right" />
							</a>
						</DownloadButtons>
						<TestimonialSliderWrapper>
							<SliderImages>
								<SliderImage src={person1} num={1} show={checkShow(1)} />
								<SliderImage src={person2} num={2} show={checkShow(2)} />
								<SliderImage src={person3} num={3} show={checkShow(3)} />
								<SliderImage src={person4} num={4} show={checkShow(4)} />
								<SliderImage src={person5} num={5} show={checkShow(5)} />
								<SliderImage src={person6} num={6} show={checkShow(6)} />
							</SliderImages>
							<TestimonialSliderContainer>
								<Slider {...settings}>
									{testimonials.map(({ node }, i) => (
										<TestimonialWrapper key={i + node.date}>
											<TestimonialContainer>
												<TestimonialHeadline>{node.title}</TestimonialHeadline>
												<StarsTest src={stars5} />
												{/* <TestimonialBody>{truncate(node.text)}</TestimonialBody> */}
												<TestimonialBody>{node.text}</TestimonialBody>
												<TestimonialFooter>
													{node.name}
													{/* <br />
													{node.date} */}
												</TestimonialFooter>
											</TestimonialContainer>
										</TestimonialWrapper>
									))}
								</Slider>
							</TestimonialSliderContainer>
						</TestimonialSliderWrapper>
					</TestimonialPage>
				);
			}}
		/>
	);
};

export default Testimonials;
