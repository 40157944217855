import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import ReactFullpage from '@fullpage/react-fullpage';

import scrollUp from '../images/home/scroll-up.svg';
import { Section, ScrollDown, StyledModal, ModalClose, LoadOverlay, BottomSpace } from '../styles';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import Landing from '../components/website/Landing';
import Cards from '../components/website/Cards';
import ContentSection from '../components/website/Section';
import SignUp from '../components/website/SignUp';
import Footer from '../components/website/Footer';
import Testimonials from '../components/website/Testimonials';

const Home = ({ data, location }) => {
	const [haveShownSignup, sethaveShownSignup] = useState(false); // check if signup route and modal open
	const [isOpen, setIsOpen] = useState(false); // modal open check
	const [load, setLoad] = useState(false); // load other sections images
	const [view, setView] = useState([]); // currently viewing index(s)
	const [viewed, setViewed] = useState([]); // all viewed indexes
	const [rendered, setRendered] = useState(false); // load other sections images
	const homeRef = useRef();

	if (location.hash === '#sign-up' && !haveShownSignup) {
		sethaveShownSignup(true);
		setIsOpen(true);
	}

	const metaTitle = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-title'
	).node.text;

	const metaDescription = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-description'
	).node.text;

	const sectionContent = section =>
		data.allGoogleContentNewSheet.edges.filter(({ node: { position } }) => position?.includes(`section${section}`));

	const dotnames = anchors =>
		data.allGoogleContentNewSheet.edges
			.filter(({ node: { position } }) => position?.includes('dotname'))
			.sort((one, two) => {
				const position1 = (one?.node?.position?.match(/\d+/g) || []).join('');
				const position2 = (two?.node?.position?.match(/\d+/g) || []).join('');
				return position1 > position2 ? 1 : -1;
			})
			.map(({ node: { text } }) => (anchors ? text.replace(/\s+/g, '-') : text));

	return (
		<Layout homeRef={homeRef}>
			<MetaData title={metaTitle} description={metaDescription} image="images/social/social.png" />

			<StyledModal isOpen={isOpen} onBackgroundClick={() => setIsOpen(false)} onEscapeKeydown={() => setIsOpen(false)}>
				<ModalClose onClick={() => setIsOpen(false)}>&times;</ModalClose>
				<SignUp isOpen={isOpen} />
			</StyledModal>

			{/* Blank Loader Overlay while Fullpage.js loads */}
			{!rendered && <LoadOverlay />}

			<ReactFullpage
				ref={homeRef}
				licenseKey="85690F4B-D7E34149-B61ED14E-0F03685A"
				scrollingSpeed={700}
				bigSectionsDestination="top"
				scrollOverflow="true"
				paddingTop="70px"
				verticalCentered="true"
				autoScrolling="true"
				normalScrollElements="#mtr-popup-container, .modal"
				navigation="true"
				navigationPosition="right"
				lockAnchors="true"
				anchors={dotnames(true)}
				navigationTooltips={dotnames()}
				onLeave={(origin, destination, direction) => {
					setView([...view, destination.index]);

					if (!viewed.includes(destination.index)) setViewed([...viewed, destination.index]);

					if (origin.index === 0 && !load) setLoad(true);
				}}
				afterLoad={(origin, destination, direction) => {
					setView([destination.index]);

					if (!viewed.includes(destination.index)) setViewed([...viewed, destination.index]);

					if (destination.index === 0 && !load)
						setTimeout(() => {
							if (!load) setLoad(true);
						}, 1300);
				}}
				afterRender={() => {
					if (!rendered) setRendered(true);
				}}
				render={({ state, fullpageApi }) => {
					const scrollTop = () => fullpageApi.moveTo(1);
					const scrollDown = () => fullpageApi.moveSectionDown();

					const setCurrentView = i => (view.includes(i) ? 'view' : '');

					const setInitialView = i => (viewed.includes(i) ? 'viewed' : '');

					return (
						<ReactFullpage.Wrapper>
							{/* =================== section 0 =================== */}
							<Section className="section">
								<Landing view={setCurrentView(0)} section={setInitialView(0)} content={sectionContent(0)} num="0" />
								<ScrollDown onClick={scrollDown} className={setInitialView(0)} />
								<BottomSpace />
							</Section>
							{/* =================== section 1 =================== */}
							<Section className="section">
								<Testimonials
									view={setCurrentView(1)}
									section={setInitialView(1)}
									content={sectionContent(1)}
									num="1"
									load={load}
								/>
								<BottomSpace />
								<ScrollDown onClick={scrollDown} className={setInitialView(1)} />
							</Section>
							{/* =================== section 2 =================== */}
							<Section className="section">
								<Cards
									view={setCurrentView(2)}
									section={setInitialView(2)}
									content={sectionContent(2)}
									num="2"
									load={load}
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(2)} />
								<BottomSpace />
							</Section>
							{/* =================== section 3 =================== */}
							<Section className="section">
								<ContentSection
									view={setCurrentView(3)}
									section={setInitialView(3)}
									content={sectionContent(3)}
									num="3"
									load={load}
									animationName="Link_card website"
									animationFile="/flares/accounts.flr"
									reverse={true}
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(3)} />
								<BottomSpace />
							</Section>
							{/* =================== section 4 =================== */}
							<Section className="section">
								<ContentSection
									view={setCurrentView(4)}
									section={setInitialView(4)}
									content={sectionContent(4)}
									num="4"
									load={load}
									animationName="Goals website"
									animationFile="/flares/goals.flr"
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(4)} />
								<BottomSpace />
							</Section>
							{/* =================== section 5 =================== */}
							<Section className="section">
								<ContentSection
									view={setCurrentView(5)}
									section={setInitialView(5)}
									content={sectionContent(5)}
									num="5"
									load={load}
									animationName="autopilot"
									animationFile="/flares/autopilot.flr"
									reverse={true}
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(5)} />
								<BottomSpace />
							</Section>
							{/* =================== section 6 =================== */}
							<Section className="section">
								<ContentSection
									section={setInitialView(6)}
									view={setCurrentView(6)}
									content={sectionContent(6)}
									num="6"
									load={load}
									animationName="send money website"
									animationFile="/flares/send.flr"
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(6)} />
								<BottomSpace />
							</Section>
							{/* =================== section 7 =================== */}
							<Section className="section">
								<ContentSection
									section={setInitialView(7)}
									view={setCurrentView(7)}
									content={sectionContent(7)}
									num="7"
									load={load}
									animationName="Untitled"
									animationFile="/flares/security.flr"
									reverse={true}
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(7)} />
								<BottomSpace />
							</Section>
							{/* =================== section 8 =================== */}
							<Section className="section">
								<ContentSection
									section={setInitialView(8)}
									view={setCurrentView(8)}
									content={sectionContent(8)}
									num="8"
									load={load}
									animationName="no fees website"
									animationFile="/flares/fees.flr"
								/>
								<ScrollDown onClick={scrollDown} className={setInitialView(8)} />
								<BottomSpace />
							</Section>
							{/* =================== section 9 =================== */}
							<Section className="section">
								<Footer home={true} />
								<ScrollDown src={scrollUp} alt="Back to top" onClick={scrollTop} className={setInitialView(9)} />
								<BottomSpace height="80px" />
							</Section>
						</ReactFullpage.Wrapper>
					);
				}}
			/>
		</Layout>
	);
};

export default Home;

export const homeQuery = graphql`
	query {
		allGoogleContentNewSheet {
			edges {
				node {
					position
					text
					text1
					text2
					text3
					text4
					text5
				}
			}
		}
	}
`;
