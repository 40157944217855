import React, { useRef } from 'react';

import SectionFlare from './Flare';
import useIntersection from '../../hooks/visible';
import { getSectionContent } from '../../utils/general';
import { SectionHeader, SectionWrapper, Body, CardHeader } from '../../styles';

const Cards = ({ section, view, content, num, load, notFullPage }) => {
	const ref = useRef();
	const inViewport = notFullPage ? useIntersection(ref, '0px') : false;
	const isInView = notFullPage ? (inViewport ? 'view' : '') : view;

	const title = getSectionContent(num, 'title', content);
	const subtitle = getSectionContent(num, 'subtitle', content);

	return (
		<div ref={ref}>
			<CardHeader>
				{title ? <SectionHeader center>{title?.text}</SectionHeader> : null}
				{subtitle ? <Body center>{subtitle?.text}</Body> : null}
			</CardHeader>
			<SectionWrapper justifyCenter>
				<SectionFlare animation="card and card options website" file="/flares/card.flr" view={isInView} />
			</SectionWrapper>
		</div>
	);
};

export default Cards;
